/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*TODO: enhance validation notes*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    strong: "strong",
    pre: "pre",
    code: "code",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Approved Starters"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/hagopj13/node-express-boilerplate"
  }, "node express boilerplate - hagopj13")), "\n"), "\n", React.createElement(_components.h2, null, "Express Frameworks"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://expresso-ts.com/"
  }, "expresso-ts")), "\n"), "\n", React.createElement(_components.h2, null, "Express Validation"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Package Install")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i express-validator\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example -inline")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "index.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "//...\nconst { validationResult, body } = require(\"express-validator\")\n\n//...\n\nconst app = express()\n\n//...\n//... validator example\napp.post(\"/users\", [\n  body(\"email\")\n    .isLength({ min: 1 })\n    .withMessage(\"email must not be empty\")\n    .isEmail()\n    .withMessage(\"must be a valid email address\"),\n  body(\"name\").isLength({ min: 1 }).withMessage(\"name must not be empty\"),\n]),\n  async (req, res) => {\n    const errors = validationResult(req)\n    if (!errors.isEmpty) {\n      return res.status(400).json(errors.mapped())\n    }\n  }\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example - abstracted")), "\n", "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "index.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "//...\nconst { validationResult, body } = require(\"express-validator\")\n\n//...\n\nconst app = express()\n\nconst userValidation = [\n  body(\"email\")\n    .isLength({ min: 1 })\n    .withMessage(\"email must not be empty\")\n    .isEmail()\n    .withMessage(\"must be a valid email address\"),\n  body(\"name\").isLength({ min: 1 }).withMessage(\"name must not be empty\"),\n]\n\n//... validation middleware\nconst checkForErrors = (req, res, next) => {\n  const errors = validationResult(req)\n  if (!errors.isEmpty) {\n    return res.status(400).json(errors.mapped())\n  } else {\n    next()\n  }\n}\n\nconst simpleValiationResult = validationResult.withDefaults({\n  formatter: (err) => err.msg,\n})\n\n//...\n//... validator example\napp.post(\"/users\", userValidation, checkForErrors),\n  async (req, res) => {\n    try {\n      return res.status(200).json({ message: \"valid input\" })\n    } catch (err) {\n      return res.status(500).json(err)\n    }\n  }\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
